<template lang="html">
  <div class="info-windows vld-parent" >
    <div class="loader-container" v-if="isLoading">
      <div class="loading-icon">
        <div class="loader">Loading...</div>
      </div>

    </div>
    <div id="google-map-area"></div>
    <modal :show="isPassengersModalShown">
      <div class="p-4 flex-col flex">
        <div class="flex items-center justify-between mt-4">
          <button
            aria-label="Close panel"
            class="transition duration-150 ease-in-out text-gray-type-2"
            @click="closePassengersModal"
          >
            <svg
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="mx-auto font-bold text-gray-type-2">
            Trip Details
          </div>
        </div>
        <div class="flex mt-6">
          <div class="font-bold text-gray-type-2">
            <p class="text-green-type-1 font-medium text-sm">Route Code:  &nbsp; <span class="text-gray-type-1 font-normal text-base">{{selectedMarkerTripData.route.route_code}}</span></p>
            <p class="text-green-type-1 font-medium text-sm">Route:  &nbsp; <span class="text-gray-type-1 font-normal text-base">{{selectedMarkerTripData.route.pickup}}</span></p>
            <p class="text-green-type-1 font-medium text-sm">Destination:  &nbsp; <span class="text-gray-type-1 font-normal text-base">{{selectedMarkerTripData.route.destination}}</span></p>
          </div>
        </div>

        <div class="flex mt-6">
          <div class="font-bold text-gray-type-2">
            Passengers
          </div>
        </div>
        <div class="mt-2">
          <div class="">
           <passengers :userRoutes="selectedUserRoutes" />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { io } from "socket.io-client";
import Modal from "@/components/Modal";
import Passengers from "./Passengers";
import { mapGetters } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";

const busIconPath = 'icons/bus.png';
const busIconUrl = `//${window.location.host}/${busIconPath}`;

const gMapLoader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["geometry"],
});

export default {
  components: {
    Passengers,
    Modal,
  },
  data() {
    return {
      isPassengersModalShown: false,
      isLoading: true,
      activeTrips: [],
      mapInstance: null,
      loading: false,
      loaded: false,
      errorLoading: false,
      activeTripsMarkers: {},
      selectedUserRoutes: [],
      selectedMarkerTripData: null,
      uniqueTripData: {},
      uniqueTripTracking: {}
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadGoogleMapsLib();
    });
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    token() {
      if (!this.user) return null;
      return this.user.token.token;
    },
  },
  beforeUnmount() {
    this.removeTripListeners();
  },
  methods: {
    fetchActiveTrips() {
      this.loadActiveTrips()
          .then((data) => {
            this.activeTrips = data;
            this.initWebSocket(this.activeTrips);
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    initWebSocket(activeTrips) {
      const socket = io(process.env.VUE_APP_WS_BASE_URL, {
        transports: ["websocket", "polling"],
        path: '/telemetry/socket.io',
        auth: { token: this.token},
        query: { token: this.token},
      });
      socket.on("connect_error", (err) => {
        console.log(err);
      });
      socket.on("connect", (v) => {
        console.log(v);
      });
      this.io = socket;
      window.io = socket;
      activeTrips.forEach(trip => {
        this.initializeTripListener(trip);
      });
    },
    removeTripListeners(){
      if(this.io)
        this.io.disconnect();
    },
    initializeTripListener(trip) {
      if(!this.uniqueTripData[trip.trip.id]) {
        this.uniqueTripData[trip.trip.id] = trip.trip;
        this.setTripCurrentMarker(trip);
        window.io.on(`trips:${trip.trip.id}`, (data) => {
           if (data) {
            this.updateTripInformation(trip, data);
          }
        });
      }
    },
    closePassengersModal() {
      this.isPassengersModalShown = false;
      this.selectedUserRoutes = [];
    },
    showPassengersModal(tripId) {
      this.selectedUserRoutes = this.activeTrips.filter(
        (i) => i.trip.id == tripId
      );
      this.selectedMarkerTripData = this.selectedUserRoutes.length ? this.selectedUserRoutes[0] : null;
      this.isPassengersModalShown = true;
    },
    updateTripInformation(tripInfo, locationInfo) {
      const prevTrackingInfo = this.uniqueTripTracking[tripInfo.trip.id];
      const {position_latitude, position_longitude} = locationInfo;

      if (!this.activeTripsMarkers[tripInfo.trip.id]) {
        this.setTripCurrentMarker(tripInfo, position_latitude, position_longitude);
      } else {
        const newLatLng = new window.google.maps.LatLng(position_latitude, position_longitude);
        this.activeTripsMarkers[tripInfo.trip.id].setPosition(newLatLng);
      }

      if (prevTrackingInfo && this.coordinateChanged(prevTrackingInfo, locationInfo)) {
        const icon = this.activeTripsMarkers[tripInfo.trip.id].getIcon();
        const rotation = this.computeHeading(
            prevTrackingInfo.position_latitude,
            prevTrackingInfo.position_longitude,
            position_latitude,
            position_longitude
        );
        icon.rotation = rotation;
        this.activeTripsMarkers[tripInfo.trip.id].setIcon(icon);
       }
      this.uniqueTripTracking[tripInfo.trip.id] = locationInfo;
    },
    coordinateChanged(previousCoordinateData, currentCoordinateData) {
      return (
          (previousCoordinateData.position_latitude !== currentCoordinateData.position_latitude) ||
          (previousCoordinateData.position_longitude !== currentCoordinateData.position_longitude));
    },
    setTripCurrentMarker(tripInfo , newLatitude = null, newLongitude = null) {
      const startingPoint = tripInfo.route.pickup_coordinate.split(",").map((i) => parseFloat(i.trim()));
      const [lat, lng] = startingPoint;
      if((lat && lng) || (newLatitude && newLongitude)) {
        this.activeTripsMarkers[tripInfo.trip.id] =
            this.addBusMarker(
                lat || 0,
                lng || 0,
                newLatitude || lat,
                newLongitude || lng,
                `${tripInfo.route.pickup}`,
                `Destination: ${tripInfo.route.destination}`,
                () => {this.showPassengersModal(tripInfo.trip.id);}
            );
      }
    },
    rotateMarker(degree) {
      const el = document.body.querySelector(`img[src$="${location.host}/${busIconPath}"]`);
      if(el) {
        el.style.transform = `rotate(${degree}deg)`;
        el.style.transformOrigin = `center`;
      }
    },
    async loadActiveTrips() {
      const res = await this.$http.get(
        `/corporates/${this.user.id}/active-trips`
      );
      return res.data;
    },
    async fetchTrackingInfo(tripId) {
      const res = await this.$http.get(`/tracking/${tripId}`);
      return res.data;
    },
    async loadGoogleMapsLib() {
      const vm = this;
      gMapLoader
        .load()
        .then(() => {
          vm.mapInstance = new window.google.maps.Map(
            document.getElementById("google-map-area"),
            {
              center: { lat: 6.5244, lng: 3.3792 },
              zoom: 12,
              streetViewControl: false
            }
          );
        })
        .finally(() => {
          vm.fetchActiveTrips();
        });
    },

    addBusMarker(prevLat, prevLng, lat, lng, title, description, onTap) {
      const contentString =
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        `<h1 id="firstHeading" class="firstHeading">${title}</h1>` +
        '<div id="bodyContent">' +
        `<p>${description}</p>` +
        "</div>" +
        "</div>";
      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      const busMarker = new window.google.maps.Marker({
        position: { lat, lng },
        map: this.mapInstance,
        icon: {
          url: busIconUrl,
          anchor: new window.google.maps.Point(0.5, 0.5),
          rotation: this.computeHeading(
            prevLat,
            prevLng,
            lat,
            lng
          ),
        },
      });
      busMarker.addListener('click',()=> {
        onTap.call(this);
      });

      infowindow.open(this.mapInstance, busMarker);
      return busMarker;
    },
    computeHeading(lat1, lng1, lat2, lng2) {
      const point1 = new window.google.maps.LatLng(lat1, lng1);
      const point2 = new window.google.maps.LatLng(lat2, lng2);
      return window.google.maps.geometry.spherical.computeHeading(
        point1,
        point2
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.info-windows {
  width: 100%;
  height: 100vh;
}
div#google-map-area {
  width: 100%;
  height: 100%;
}

.vld-parent {
  position: relative;
}

.loader-container {
  position: absolute;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.loading-icon {
  width: 80px;
  height: 80px;
  z-index: 10000;
  margin: auto;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>